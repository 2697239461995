<template>
  <v-autocomplete
    ref="auto"
    placeholder="Seleccionar Tipo de Transferencia"
    label="TT"
    item-text="name"
    persistent-hint
    :loading="loading"
    item-value="id"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :items="TTList"
    :rules="rules"
    :search-input.sync="search"
    :readonly="disabled"
    clearable
  ></v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  // props: ["required", "value", "reset","edit"],
  props: {
    required: { type: Boolean, required: false },
    value: { type: Number, required: false },
    reset: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false, default: false },
    credeb: { type: String, required: true },
  },
  data() {
    return {
      TTList: [],
      loading: false,
      search: "",
      //rules
      rules: [],
    };
  },
  methods: {
    getTT(param = "") {
      this.loading = true;
      let uri = "/api/catalogs/TT/?credeb=" + this.credeb + "&search=";
      if (this.search) {
        uri += param;
      }
      requests.get(uri).then((res) => {
        this.loading = false;
        this.TTList = res.data.results;
      });
    },
  },
  mounted() {
    this.getTT();
    if (this.required == null) {
      this.rules = [];
    } else {
      this.rules = [rules.required];
    }
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
      this.getTT();
    },
    search: function () {
      this.getTT(this.search);
    },
    credeb: function () {
      this.getTT(this.search);
    },
  },
};
</script>
